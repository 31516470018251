import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

export default function Home() {
    return (
        <div>
            <div className='flex justify-center items-center pt-40 max-sm:pt-20'>
                <img className='w-60' src='https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/LOGO/al%20bidaya.png' alt='Al Bidayah' />
            </div>
            <div className='px-40 max-sm:px-10 py-10 text-justify'>
                {/* <p>
                    <b>AlBidayah Limited Liability Company (LLC)</b>, based in the Kingdom of Saudi Arabia, is a leading service provider, specializing in a diverse range of sectors including Hajj & Umrah services, tourism, transportation, medical services, education, and restaurant management. With a deep commitment to excellence, AlBidayah LLC ensures that each of its services is delivered with the highest standards of quality and care. From organizing seamless pilgrimage experiences to offering world-class educational support and comprehensive medical services, the company is dedicated to enriching the lives of its clients. AlBidayah LLC prides itself on a customer-centric approach, leveraging its expertise across industries to provide innovative solutions that meet the evolving needs of both local and international communities.
                </p> */}
            </div>
            <div className='flex max-sm:block justify-center items-center'>
                <div className='px-40 max-sm:px-10 space-y-7 max-sm:py-10 w-1/2 max-sm:w-full'>
                    <p className='font-semibold text-5xl'>Restaurant.</p>
                    <p className='text-xl text-justify'>Savor every bite at our place, where every meal is a masterpiece. Come for the food, stay for the flavor!</p>
                </div>
                <div className='w-1/2 max-sm:w-full'>
                    <AliceCarousel
                        autoPlay
                        disableButtonsControls
                        disableDotsControls
                        autoPlayInterval={2000}
                        buttonsDisabled={false}
                        dotsDisabled={true}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        responsive={{
                            0: { items: 1 },
                            568: { items: 1 },
                            1024: { items: 1 },
                        }}
                    >
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_Create_a_space_design_rendering_of_a_hot_pot_resta_6da160df-2816-4ef9-ad14-0197e4077e51.png" alt="Restaurant" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_Create_a_space_design_rendering_of_a_hot_pot_resta_c202b886-2611-4b6a-af44-d212e22423f1.png" alt="Restaurant" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_Create_a_space_design_rendering_of_a_hot_pot_resta_0363fd88-d6b6-4c81-a196-f85c0e5d11a8.png" alt="Restaurant" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_Create_a_space_design_rendering_of_a_hot_pot_resta_b24fbe8f-fd7c-4a8c-8670-cd4fef416223.png" alt="Restaurant" preload="auto" /></div>
                    </AliceCarousel>
                </div>
            </div>
            <div className='flex max-sm:flex-wrap-reverse justify-center items-center'>
                <div className='w-1/2 max-sm:w-full'>
                    <AliceCarousel
                        autoPlay
                        disableButtonsControls
                        disableDotsControls
                        autoPlayInterval={2000}
                        buttonsDisabled={false}
                        dotsDisabled={true}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        responsive={{
                            0: { items: 1 },
                            568: { items: 1 },
                            1024: { items: 1 },
                        }}
                    >
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_photorealistic_image_of_muslim_people_going_for_pi_2ab85fcc-0797-4adf-b666-cf88652af771.png" alt="Ria Travels" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_photorealistic_image_of_muslim_people_going_for_pi_e80452b0-7e1f-4159-a0cf-5472bed6a4ee.png" alt="Ria Travels" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_photorealistic_image_of_muslim_people_going_for_pi_c998fbce-5a00-4a7f-8e36-60fcdb95bb21.png" alt="Ria Travels" preload="auto" /></div>
                    </AliceCarousel>
                </div>
                <div className='px-40 max-sm:px-10 space-y-7 max-sm:py-10 w-1/2 max-sm:w-full'>
                    <p className='font-semibold text-5xl'>Ria Travels.</p>
                    <p className='text-xl text-justify'>Experience the divine and explore the world with Ria Travels—your trusted guide for Hajj, Umrah, and unforgettable journeys. From sacred pilgrimages to seamless travels, we’ve got you covered!</p>
                </div>
            </div>
            {/* <div className='flex max-sm:block justify-center items-center'>
                <div className='px-40 max-sm:px-10 space-y-7 w-1/2 max-sm:w-full'>
                    <p className='font-semibold text-5xl'>MHC Global.</p>
                    <p className='text-xl text-justify'>Reach new heights with MHC Global—where college aviation degrees and cutting-edge school programs meet innovation and agriculture. Discover, learn, and lead with us!</p>
                </div>
                <div className='w-1/2 max-sm:w-full'>
                    <AliceCarousel
                        autoPlay
                        disableButtonsControls
                        disableDotsControls
                        autoPlayInterval={2000}
                        buttonsDisabled={false}
                        dotsDisabled={true}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        responsive={{
                            0: { items: 1 },
                            568: { items: 1 },
                            1024: { items: 1 },
                        }}
                    >
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mhcockpit_8k_ultra_realistic_indian_trainee_pilot_with_white_un_d455f354-4027-4795-92e0-98ca84895698.png" alt="MHC Global" preload="auto" /></div>
                    </AliceCarousel>
                </div>
            </div> */}
            <div className='flex max-sm:block justify-center items-center'>
                <div className='px-40 max-sm:px-10 space-y-7 max-sm:py-10 w-1/2 max-sm:w-full'>
                    <p className='font-semibold text-5xl'>Sun Al Bidayah.</p>
                    <p className='text-xl text-justify'>Experience the divine and explore the world with Ria Travels—your trusted guide for Hajj, Umrah, and unforgettable journeys. From sacred pilgrimages to seamless travels, we’ve got you covered!</p>
                </div>
                <div className='w-1/2 max-sm:w-full'>
                    <AliceCarousel
                        autoPlay
                        disableButtonsControls
                        disableDotsControls
                        autoPlayInterval={2000}
                        buttonsDisabled={false}
                        dotsDisabled={true}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        responsive={{
                            0: { items: 1 },
                            568: { items: 1 },
                            1024: { items: 1 },
                        }}
                    >
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_an_8k_very_detailed_photorealistic_image_of_an_man_f24bca6a-d465-4b89-90f5-3f745fd78044.png" alt="Sun Al Bidayah" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_an_8k_very_detailed_photorealistic_image_of_an_man_e20390ce-62f7-45ee-a2b1-914e933ec9e4.png" alt="Sun Al Bidayah" preload="auto" /></div>
                    </AliceCarousel>
                </div>
            </div>
            <div className='flex max-sm:flex-wrap-reverse justify-center items-center'>
                <div className='w-1/2 max-sm:w-full'>
                    <AliceCarousel
                        autoPlay
                        disableButtonsControls
                        disableDotsControls
                        autoPlayInterval={2000}
                        buttonsDisabled={false}
                        dotsDisabled={true}
                        infinite={true}
                        swipeable={true}
                        draggable={true}
                        responsive={{
                            0: { items: 1 },
                            568: { items: 1 },
                            1024: { items: 1 },
                        }}
                    >
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_tourists_with_tourist_guide_in_malayasia_tourist_g_5f1aca3a-65e8-4d6f-b972-08c96e75fc1f.png" alt="Laksiri Seva" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_tourists_with_tourist_guide_in_malayasia_tourist_g_186c372c-c960-45d6-b23b-2f4abaadf1cc.png" alt="Laksiri Seva" preload="auto" /></div>
                        <div><img src="https://ik.imagekit.io/1sb7q2nfg/AL%20BIDAYAH/Home/mh_intellect_tourists_with_tourist_guide_in_malayasia_tourist_g_0da9d79e-ddb0-4f42-aa5f-a6ec91ddace9.png" alt="Laksiri Seva" preload="auto" /></div>
                    </AliceCarousel>
                </div>
                <div className='px-40 max-sm:px-10 space-y-7 max-sm:py-10 w-1/2 max-sm:w-full'>
                    <p className='font-semibold text-5xl'>Laksiri Seva.</p>
                    <p className='text-xl text-justify'>Reach new heights with MHC Global—where college aviation degrees and cutting-edge school programs meet innovation and agriculture. Discover, learn, and lead with us!</p>
                </div>
            </div>
        </div>
    )
}
