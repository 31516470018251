import React, { useState } from "react";
import Navbar from "./Components/Header/Navbar";
import { Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import Home from "./Pages/Home/Home";

function App() {

  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false);

  return (
    <div>
      <Navbar setBackgroundBlur={setIsBackgroundBlurred} />
      <div id="background" className={`xl:pt-12 max-sm:pt-12 ${isBackgroundBlurred ? '' : ''}`}>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="*"
            element={<Navigate to="/" />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
