import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./Navbar.css";

export default function Navbar({ setBackgroundBlur }) {
    const [activeNav, setActiveNav] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);
    const navigate = useNavigate();

    const handleMouseOver = (index) => {
        setActiveNav(index);
        setBackgroundBlur(true); // Apply blur on mouseover
    };

    const handleMouseOut = () => {
        setActiveNav(null);
        setBackgroundBlur(false); // Remove blur on mouseout
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Close the menu when navigating to a new page
        setIsMenuOpen(false);
    }, [navigate]);

    const navItems = [
        {
            title: "About Us",
            link: "/",
            subItems: [
                {}
            ]
        },
        {
            title: "Sun Al Bidayah",
            link: "/",
            subItems: [
                { name: "Man Power", link: "/" },
                { name: "Visa Service", link: "/" },
                { name: "Ksa Gov Service", link: "/" },
                { name: "Ticket Booking", link: "/" },
                { name: "Transport", link: "/" }
            ]
        },
        // {
        //     title: "MHC Global",
        //     link: "https://www.mhglobal.info",
        //     subItems: [
        //         {}
        //     ],
        //     target: "blank"
        // },
        {
            title: "Restaurant",
            link: "/",
            subItems: [
                { name: "Singapore", link: "/" },
                { name: "Malaysia", link: "/" },
                { name: "UAE", link: "/" }
            ]
        },
        {
            title: "Ria Travels",
            link: "/",
            subItems: [
                { name: "Haj Service", link: "/" },
                { name: "Umrah Service", link: "/" },
                { name: "Malaysia Tourism", link: "/" },
                { name: "Singapore Tourism", link: "/" }
            ]
        },
        {
            title: "Laksiri Seva",
            link: "/",
            subItems: [
                { name: "Sri Lanka", link: "/" },
                { name: "Malaysia", link: "/" },
                { name: "KSA", link: "/" }
            ]
        },
    ];


    return (
        <nav className='bg-black px-4 py-3 md:px-10 max-md:py-1 sm:py-6 max-sm:py-6 xl:py-1 flex justify-center xl:justify-center sm:justify-start max-sm:justify-start items-center fixed w-full z-50'>
            {/* <div>
                <a href='/'>
                    <img className='w-32 absolute top-4 left-10 xl:left-10 sm:left-5 max-sm:left-5' src='https://ik.imagekit.io/mhcockpit1/MH%20COCKPIT%20LOGO%20FOR%20WEBSITE%20RED.png' alt='LOGO' />
                </a>
            </div> */}
            <div>
                <div className='flex justify-between items-center'>
                    <div className='md:block xl:hidden fixed top-3 right-3'>
                        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className='text-white focus:outline-none'>
                            <svg className='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                                <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M4 6h16M4 12h16m-7 6h7'></path>
                            </svg>
                        </button>
                    </div>
                    <ul ref={menuRef} className={`text-white space-x-14 xl:space-x-14 md:space-x-0 md:pt-5 sm:space-x-0 sm:pt-5 max-sm:space-x-0 max-sm:pt-5 lg:pt-5 xl:pt-0 ${isMenuOpen ? 'flex flex-col md:flex-col lg:flex max-md:flex max-lg:flex' : 'hidden sm:hidden md:hidden xl:flex'}`}>
                        {navItems.map((item, index) => (
                            <li
                                key={index}
                                className='heading md:relative'
                                onMouseOver={() => handleMouseOver(index)}
                                onMouseOut={handleMouseOut}
                            >
                                <Link to={item.link} target={item.target} className='block px-4 py-2 md:px-0'>{item.title}</Link>
                                <ul className={`nav-list ${activeNav === index ? 'expanded space-y-2' : 'no_expand'}`}>
                                    {item.subItems.map((subItem, subIndex) => (
                                        <li key={subIndex} className='sub-1 max-sm:pl-5'>
                                            <Link to={subItem.link}>{subItem.name}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div>
                <button onClick={() => navigate('/')} className='bg-sky-500 text-white font-semibold rounded-md absolute right-10 top-2 p-1 px-2 animate-pulse hover:scale-105 transition-transform duration-1000 ease-in-out'>Contact Us</button>
            </div>
        </nav>
    );
}
